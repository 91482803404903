.App {
  text-align: center;
  background: black;
  color: white;
  display: block;
  font-family: 'Cinzel', serif;
}

.App h1 {
  font-family: 'Cinzel',serif;
}

.App h3 {
  font-family: 'Cinzel',serif;
}

.App h4 {
  font-family: 'Cinzel',serif;
}

.App h5 {
  font-family: 'Cinzel',serif;
}
.Navigation {
  color:green;

}
.banner{
  height: 675px;
  position: relative;
  background: url('https://images.unsplash.com/photo-1558904541-efa843a96f01?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2378&q=80') center / cover;
  padding-top: 25px; 
}
.logo {
  display: block;
  margin-left: auto; 
  margin-right: auto; 
  border-radius: 25%;
  height: 200px;
  width: 200px;
}

.info-div {
  color:white;
  background: #078929;
  width:80%;
  padding: 15px;
  display:block;
  margin:auto;
  margin-top: 25px;
  opacity: .9;
  border-radius: 10px;
  border: solid white 2px;
}

.info-div hr{
  border-top: 5px solid white;
  font-size: 20px;
  width: 60%;
  margin:auto;
}

.social-links {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  margin: auto;
}

.social-links a {
  font-size: 4em;
  padding-left: 20px;
  padding-right: 20px;
}

.contact-info-div {
  color:white;
  background: #078929;
  width:100%;
  padding: 15px;
  display:block;
  margin:auto;
  margin-top: 25px;
  opacity: .9;
  border-radius: 10px;
  border: solid white 2px;
}

.contact-info-div hr{
  border-top: 5px solid white;
  font-size: 20px;
  width: 60%;
  margin:auto;
}

.about-body{
  margin: auto;
  width: 100%;
  justify-content: center; 
  margin-top: 30px;
}

.about-grid{ 
  text-align: center;
  justify-content: center;
  width: 90%;
}

.about-image {
  width: 100%;
  justify-content: center;
}

.mower-div{
  display: block;
  margin-left: auto; 
  margin-right: auto;
  margin-top: 50px; 
}
.Service-cards{
  display: flex;
  flex-wrap: wrap;
}

.service-card{
  display: block;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.service-card-text{
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-left: 25px;
}

.gallery{
  height:75%;
  width:90%;
}

.contact-grid{ 
  text-align: center;
  justify-content: center;
}

.contact-form{
  width:90%;
  display: block;
  margin-top: 50px;
  margin-left:auto;
  margin-right:auto;
}

.home-contact-div{
  padding-left:15%;
  padding-right:15%;
}

.contact-form Form{
  margin-top: 25px;

}

.footer{
  margin-top:50px;
  padding: 2%;
  width:100%;
  background: grey;
  color: black;
}

.footer-links {
  margin-top:15px;
}

.footer-links a{
  color:white;
  padding-left: 2%;
  padding-right: 2%;
}